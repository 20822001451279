import React, { Component } from 'react';
import './Scene.css';
import Hand from './view/Hand';
import Champion from './view/Champion';
import Enemies from './view/Enemies';
import Entity from './view/Entity';
import Court from './view/Court';
import Field from './view/Field';
import Hero from './view/Hero';
import Reward from './view/Reward';
import LevelUp from './view/LevelUp';
import Campfire from './view/Campfire';
import CardSelector from './view/CardSelector';
import CardDropper from './view/CardDropper';
import DeckViewer from './view/DeckViewer';
import Abilities from './view/Abilities';
import Choosebox from './view/Choosebox';
import Cutscene from './view/Cutscene';
import RotateMessage from '../components/utility/RotateMessage';

import { createStore } from 'redux';
import reducers from './reducers';
import { Tooltip } from 'reactstrap';

import Lightbox from '../components/utility/Lightbox';
import SceneButton from '../components/buttons/SceneButton';
import MainButton from '../components/buttons/MainButton';
import Card from '../components/cards/Card';
import Ability from '../components/cards/Ability';
import CardBox from '../components/cards/CardBox';
import SocketManager from '../SocketManager';
import Sequencer from './Sequencer';
import Logs from './Logs';
import Error from '../components/home/Error';
import Back from '../components/Back';

import PlayingState from './controller/PlayingState';
import WaitingState from './controller/WaitingState';
import HandChoiceState from './controller/HandChoiceState';
//import MovingState from './controller/state/MovingState';

import { read } from '../TextManager';
import { play, stopMusic, stopCategory } from '../SoundManager';
import CutsceneManager from './CutsceneManager';

import Library from './utility/Library';
import User from '../User';
import Img from '../StableImage';

import Run from './model/Run';
import Broadcaster from './Broadcaster';

const backgrounds = [
  "floor1",
  "floor2",
  "floor3"
]

const audio = [
  "Tenno - The Guiding Wind",
  "Tenno - Sacred Tree",
  "Tenno - Between Worlds"
]

export default class Scene extends Component {
	
  constructor (props) {

    super(props);

    this.store = createStore(reducers);
    this.store.subscribe(() => {
      this.setState({model: this.store.getState()}, () => { if (this.sequencer.resume) this.sequencer.resume(); });
    });

    this.checkAudio = () => {

      if (this.audioPlaying && !this.cutsceneManager.cutscene)
        play(this.run.audio || audio[this.run.depth-1], "music");
    }

    document.addEventListener('click', this.checkAudio, { once: true });
    document.addEventListener('keydown', this.checkAudio, { once: true });

    this.volume = 1;
    //this.noPlayer = 0;
    this.controller = new WaitingState(this);
    this.sequencer = new Sequencer(this, n => this.store.dispatch(n));

    this.cutsceneManager = new CutsceneManager(this, c => this.cutscene(c));

    //let decks = JSON.parse(localStorage.getItem('decks'))
    //let activedeck = decks.filter(deck => deck.key.toString() === localStorage.getItem('activedeck'))[0].body;
    //this.io.gamemode('room', activedeck);

    let broadcaster = new Broadcaster();

    let save = User.currentRun;
    this.run = null;
    if (save) {
      this.run = Run.build(save, broadcaster);
      setTimeout(() => {
        let savelog = User.getItem("run.log");
        if (savelog)
          broadcaster.log = JSON.parse(savelog);
        if (this.run?.room?.fight?.choosingHand)
          this.controller = new HandChoiceState(this, this.run.room.fight);
        else if (this.run?.room?.fight?.turn === "player")
          this.controller = new PlayingState(this, this.run.room.fight);
        else
          this.controller = new WaitingState(this);
        this.run.notify('gamestate', this.run.serialize(), broadcaster.log);
      }, 10);
    } else {
      this.run = new Run();
      setTimeout(() => {
        this.run.notify('newrun', this.run, this.run.champion.model.key)
        this.run.start();
      }, 10);
    }
    
    if (!save) {
      let floor = localStorage.getItem("run.floor") || 1;
      floor = Math.min(Math.max(User.progress[User.difficulty][User.championSelect].level-1, 1), floor);
      localStorage.removeItem("run.floor");
      this.run.init(broadcaster, User.championSelect, User.difficulty, floor);
    }
    broadcaster.start(this);
    

    this.grabbing = null;
    this.dragged = React.createRef();
    this.startTime = Date.now(); // Record the start time when the component mounts
    //this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
    this.state = {
      model: this.store.getState(),
      focus:null,
      casting: null,
      tooltip: null, 
      deckviewer: false
    }
  }

  componentWillUnmount () {

    stopMusic();

    //this.handleBeforeUnload();
    //document.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('click', this.checkAudio);
    document.removeEventListener('keydown', this.checkAudio);
  }

  /*handleBeforeUnload() {

    const endTime = Date.now();
    const timeSpent = endTime - this.startTime;
    if (this.run)
      User.addStats(this.run.difficultySetting, "timePlayed", this.run.champion.model.key, Math.floor(timeSpent / 60000));
  }*/

  componentDidMount() {

    //window.addEventListener('beforeunload', this.handleBeforeUnload);
  }

  save () {

    if (this.run.over)
      return;
    User.setItem("run.save", JSON.stringify(this.run.serialize()));
    User.setItem("run.log", JSON.stringify(this.run.broadcaster.log));
  }

  get playing () { return this.controller.name === "playing" }
  get targeting () { return (this.controller.name === "playing" && this.controller.selected && this.controller.selected.hasTarget ? this.controller.selected : null) }
  get waiting () { return this.controller.name === "waiting" }
  get choosing () { return this.controller.name === "choosing" }

  notify (type, data) {

    this.cutsceneManager.event({type, data}, () => {
      this.sequencer.add({n: {type, data}, callback: () => this.actUponUpdate(type, data)}); 
    })
  }

  cutscene (c) {

    this.setState({cutscene: c ? Object.assign({}, this.state.cutscene, c) : null});
  }

  actUponUpdate (type, data) {

    switch (type) {
    case "gamestate":
    case "newfloor":
      this.audioPlaying = true;
      play(this.run.audio || audio[this.run.depth-1], "music");
      break;
    case "victory":
      let progress = User.progress;
      let quest = User.getQuest(this.run.difficultySetting, this.run.champion.model.key, this.run);
      if (this.run.storyEvent) {
        User.completeQuest(this.run.difficultySetting, this.run.champion.model.key);
        User.addRun(this.run);
        User.runes += this.run.runes;
        User.removeItem("run.save");
        User.removeItem("run.log");
      }
      else if (quest && !(quest && quest.active) && this.run.depth === progress[this.run.difficultySetting][this.run.champion.model.key].level && this.run.depth < 3) {
        User.completeQuest(this.run.difficultySetting, this.run.champion.model.key);
        User.addRun(this.run);
        User.runes += this.run.runes;
        User.removeItem("run.save");
        User.removeItem("run.log");
        let newLevel = User.progress[this.run.difficultySetting][this.run.champion.model.key].level;
        this.setState({unlocks: [
          { type: "level", img: this.run.champion.model.img[newLevel-1], text: "Growth level " + newLevel + " unlocked!" },
          { type: "stage", img: "/images/backgrounds/" + backgrounds[newLevel-1] + ".png", text: "Stage " + newLevel + " unlocked!" }
        ]})
      } else if (!(quest && quest.active)) {
        User.addRun(this.run);
        User.runes += this.run.runes;
        User.removeItem("run.save");
        User.removeItem("run.log");
      }
      this.controller = new WaitingState(this);
      break;
    case "gameover":
      this.undoRunStats = User.addRun(this.run);
      User.runes += this.run.runes;
      User.removeItem("run.save");
      User.removeItem("run.log");
      this.controller = new WaitingState(this);
      break;
    case "continue":
      this.undoRunStats();
      this.setState({tooltip: null});
      this.save();
      break;
    case "playerturn":
      this.save();
    case "chosehand":
      this.controller = new PlayingState(this, this.run.room.fight);
      break;
    case "shopbuy":
      this.save();
      break;
    case "newcardchoice":
    case "campfire":
    case "pathchoices":
    case "fightreward":
    case "levelup":
    case "fillshop":
      this.setState({tooltip: null});
      this.save();
      break;
    case "item":
    case "relic":
      this.setState({tooltip: null});
      break;
    case "endplayerturn":
      this.controller = new WaitingState(this);
      break;
    case "handchoice":
      this.controller = new HandChoiceState(this, this.run.room.fight);
      break;
    default: break;
    }
  }

  toggleTooltip (tooltip, touch) {

    if (tooltip === this.state.tooltip && this.state.tooltipDate && new Date() - this.state.tooltipDate < 500)
      return;

    if (touch) {

      this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip, tooltipDate: new Date()});
      this.tooltipTouch = touch;
      return;
    }

    this.setState({tooltip: tooltip === this.state.tooltip ? null : tooltip});
  }

  onSelectCard (e, card) {

    if (this.controller.selectCard) {
      this.controller.selectCard(card, e && e.pointerType === "touch");
      this.forceUpdate()
    }
  }

  onSelectEntity (e, entity) {

    if (this.controller.selectEntity)
      this.controller.selectEntity(entity);
  }

  endTurn () {

    if (this.controller.endTurn)
      this.controller.endTurn();
  }

  unselect () {

    if (this.controller && this.controller.unselect) {
      this.controller.unselect();
      this.setState({tooltip: null})
      this.forceUpdate();
    }
  }

	render () {

    if (this.state.model === 0)
      return (
      <div className="scene">
        {/*<div className="scene-background">
          <img src={"/images/backgrounds/" + backgrounds[0] + ".png"}/>
        </div>*/}
        {
          this.state.cutscene ?
          <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
        }
      </div>);

    let premium = User.premium;
    let quest = User.getQuest(this.run.difficultySetting, this.run.champion.model.key, this.run);
    if (quest && quest.key !== this.run.quest)
      quest = null;
    let progress = User.progress[this.run.difficultySetting][this.run.champion.model.key];//console.log(this.state.tooltip)

		return (
      <div id="scene" className={"scene playing" + (localStorage.getItem('options.fastmode') ? " fastmode" : "") + (this.state.model.over || this.state.model.win ? " scene-over" : "") + (this.state.model.droppingItem ? " scene-drop-item" : "") + (this.state.model.droppingRelic ? " scene-drop-relic" : "")}>
        <RotateMessage/>
        <div className={"scene-background background-" + (this.state.model.background ?? (this.state.model.deckbuilder ? "deckbuilder" : backgrounds[this.state.model.depth-1])) + (this.state.model.room?.shop && !this.state.model.campfireChoices && !this.state.model.pathChoices ? " shop-background" : "")} onClick={() => this.unselect()}>
          { this.state.model.deckbuilder ? "" : <Img key={"background-" + (this.state.model.background ?? (this.state.model.deckbuilder ? "deckbuilder" : backgrounds[this.state.model.depth-1]))} src={"/images/backgrounds/" + (this.state.model.background ?? (this.state.model.deckbuilder ? "deckbuilder" : backgrounds[this.state.model.depth-1])) + ".png"}/> }
          <div className="background-particle"/><div className="background-particle"/><div className="background-particle"/><div className="background-particle"/>
        </div>
        <div className="champion-info">
          <div id="champion-level" onPointerDown={e => this.toggleTooltip("champion-level", e.pointerType === "touch")} className="champion-stat level">
            <Img src={"/images/level/" + this.state.model.champion.model.name + ".png"}/>
            <div className="stat-count">{this.state.model.champion.level}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "champion-level"} target="champion-level" toggle={() => this.toggleTooltip("champion-level")}><b>Level</b><br/>{this.run.champion.model.passive.effect.replace(/\[|\]/g, '').replace(/1/g, (this.run.champion.level * (this.run.champion.level+1))/2)}</Tooltip>
          <div id="champion-health" onPointerDown={e => this.toggleTooltip("champion-health", e.pointerType === "touch")} className="champion-stat health">
            <Img src="/images/health.png"/>
            <div className="stat-count">{this.state.model.champion.hp}/{this.state.model.champion.maxhp}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "champion-health"} target="champion-health" toggle={() => this.toggleTooltip("champion-health")}><b>Health</b><br/>Don't let it drop to 0!</Tooltip>
        </div>
        <div className="champion-info">
          <div id="gold" onPointerDown={e => this.toggleTooltip("gold", e.pointerType === "touch")} className="champion-stat gold">
            <Img src="/images/gold.png"/>
            <div className="stat-count">{this.state.model.champion.gold}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "gold"} target="gold" toggle={() => this.toggleTooltip("gold")}><b>Gold</b><br/>Currency to buy cards and items at shops.</Tooltip>
          {/*<div id="corruption" onPointerDown={() => this.toggleTooltip("corruption")} className="champion-stat corruption">
            <Img src="/images/corruption.png"/>
            <div className="stat-count">{this.state.model.champion.corruption}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "corruption"} target="corruption" toggle={() => this.toggleTooltip("corruption")}><b>Corruption</b><br/>No use in this update.</Tooltip>*/}
          <div id="time" onPointerDown={e => this.toggleTooltip("time", e.pointerType === "touch")} className="champion-stat time">
            <Img src="/images/time.png"/>
            <div className="stat-count">{this.state.model.storyEvent ? "SP" : this.state.model.time}</div>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "time"} target="time" toggle={() => this.toggleTooltip("time")}><b>Time remaining</b><br/>Number of rooms before you fight a boss.</Tooltip>
        </div>
          <div id="champion-difficulty" onPointerDown={e => this.toggleTooltip("difficulty", e.pointerType === "touch")} className="champion-difficulty">
            <Img src={"/images/" + this.run.difficultySetting + ".png"}/>
          </div>
          <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "difficulty"} target="champion-difficulty" toggle={() => this.toggleTooltip("difficulty")}>{ this.run.challenge ? <span><b>Challenge mode</b><br/>The maze is unforgiving.</span> : (this.run.zen ? <span><b>Zen mode</b><br/>The maze is your ally.</span> : (this.run.hardcore ? <span><b>Hardcore mode</b><br/>The ultimate trial.</span> : <span><b>Casual mode</b><br/>The maze welcomes all.</span>))}</Tooltip>
        <div id="deck-viewer-open" className={"deck-viewer-open-wrapper" + (this.state.deckviewer ? " open" : "")}>
          <div className="deck-viewer-open" onClick={() => this.setState({deckviewer: this.state.deckviewer ? null : this.state.model.deck})}>
            <Img src="/images/card.png"/>
          </div>
        </div>
        {/*<Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "deck-viewer-open" && !this.state.deckviewer} target="deck-viewer-open" toggle={() => this.toggleTooltip("deck-viewer-open")}><b>Deck viewer</b><br/>Cards your deck starts with every fight.</Tooltip>*/}
        <div id="exp-bar" className={"exp-bar-wrapper" + (this.state.model.champion.exp >= this.state.model.champion.threshold || this.state.model.levelingUp ? " levelup" : "")}>
          <div className="exp-bar-text">
            {
              this.state.model.champion.level >= this.state.model.champion.maxLevel
              ? "Max level" : (
                this.state.model.champion.exp >= this.state.model.champion.threshold || this.state.model.levelingUp
                ? "Level Up!"
                : this.state.model.champion.exp + "/" + this.state.model.champion.threshold
              )
            }
          </div>
          <div className="exp-bar" onPointerDown={e => this.toggleTooltip("exp-bar", e.pointerType === "touch")}>
            <div className="exp-bar-fill" style={{width: (this.state.model.levelingUp ? 100 : (100 * this.state.model.champion.exp / this.state.model.champion.threshold)) + "%"}}/>
          </div>
        </div>
        <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "expbar"} target="exp-bar" toggle={() => this.toggleTooltip("expbar")}><b>Experience gauge</b><br/>Fill it up to level up.</Tooltip>
        <div className="scene-item-list scene-relic-list">
        {
          [0,1].map(n => this.state.model.champion.relics[n]).map((relic,i) =>
            <div key={"relic-" + i} className="scene-item">
              <Img className="item-placeholder" key={"relic-" + i + "-placeholder"} src="/images/relic.png"/>
              {
                relic ?
                <div id={"relic-" + i} onPointerDown={e => this.toggleTooltip("relic-" + i, e.pointerType === "touch")} className={"inner-item scene-item" + (this.state.model.droppingRelic ? " usable" : "")} onClick={() => { if (this.state.model.droppingRelic) this.run.champion.dropRelic(i); } }>
                  <Img key={"relic-" + i + "-" + relic.img} src={relic.img}/>
                  <Tooltip className="tooltip" placement="bottom" isOpen={this.state.tooltip === "relic-" + i} target={"relic-" + i} toggle={() => this.toggleTooltip("relic-" + i)}><b><img src="/images/relic.png"/>{ relic.name }</b><br/>{ relic.effect }</Tooltip>
                </div> : ""
              }
            </div>
          )
        }
        </div>
        <div className="scene-item-list">
        {
          [0,1,2,3,4].map(n => this.state.model.champion.items[n]).map((item,i) =>
            <div key={"item-item-" + i} className="scene-item">
              { i < 4 ? <Img className="item-placeholder" key={"item-" + i + "-placeholder"} src="/images/item.png"/> : "" }
              {
                item ?
                <div>
                  <div id={"item-" + i} className={"inner-item scene-item" + (!item.fight || this.state.model.droppingItem || (this.playing && this.state.model?.room?.fight && !this.state.model.room.fight.done) ? " usable" : "")} onPointerDown={e => {
                    this.toggleTooltip("item-" + i, e.pointerType === "touch");
                    if (e.pointerType === "touch")
                      return;
                    if (!item.fight || this.state.model.droppingItem || (this.playing && this.state.model?.room?.fight && !this.state.model.room.fight.done))
                      this.run.champion.useItem(i);
                  }}>
                    <Img key={"item-" + i + "-" + item.img} src={item.img}/>
                  </div>
                  <Tooltip className="tooltip" placement="bottom" isOpen={item && this.state.tooltip === "item-" + i} target={"item-" + i} toggle={() => this.toggleTooltip("item-" + i)}>
                  <b>{ item.name }</b><br/>
                  { item.effect }
                  { this.tooltipTouch && (!item.fight || this.state.model.droppingItem || (this.playing && this.state.model?.room?.fight && !this.state.model.room.fight.done)) && <div className="tooltip-button" onClick={() => this.run.champion.useItem(i)}>Use</div> }
                  </Tooltip>
                </div> : ""
              }
            </div>
          )
        }
        </div>
        {
          quest ?
          <div className={"scene-quest" + (quest.active ? " active" : "")} onClick={quest.callback || (() => {})}>
            <h2>Quest</h2>
            <div className="quest-text">{quest.text}</div>
          </div>
          : ""
        }
      {
        this.state.model.room?.shop && !this.state.model.campfireChoices && !this.state.model.pathChoices ?
        <div className={"fight-scene " + (this.controller.name)}>
          <Champion src={this.state.model.champion} onSelect={() => {}}/>
          <div className="shop-items-wrapper">
            <div className={"shop-items items-" + this.state.model.room.shop.articles.length}>
            {
              this.state.model.room.shop.articles.map((article,i) =>
                article.sold ?
                <div key={"shop-article-sold-" + i} className="shop-article sold"/> :
                <div key={"shop-article-" + i + "-" + (article.value.type) + "-" + (article.value.key)} className="shop-article">
                  <div key={"shop-item-wrapper-" + i + (article.value.type) + "-" + (article.value.key)} id={"shop-item-" + i} onPointerDown={e => this.toggleTooltip("shop-item-" + i, e.pointerType === "touch")} className="shop-item-wrapper" onPointerDown={e => {
                    this.toggleTooltip("item-" + i, e.pointerType === "touch");
                    if (e.pointerType === "touch")
                      return;
                    this.run.room.shop.buy(i);
                  }}>
                    <div key={"shop-item-item-" + i + (article.value.type) + "-" + (article.value.key)} className="shop-item">
                      {
                        article.type === "card" ?
                        <Card src={article.value}/> :
                        <Img key={"shop-item-" + i + "-" + article.value.img} src={article.value.img}/>
                      }
                    </div>
                    <div className="shop-price">
                      <Img src={"/images/" + this.state.model.room.shop.currency + ".png"}/>
                      <div className="shop-price-value">{article.price}</div>
                    </div>
                    {
                      article.type === "card" ? "" :
                      <div className="shop-article-watermark">
                        <Img src={"/images/" + article.type + ".png"}/>
                      </div>
                    }
                  </div>
                  <Tooltip className="tooltip" placement="top" isOpen={article && !article.sold && this.state.tooltip === "shop-item-" + i} target={"shop-item-" + i} toggle={() => this.toggleTooltip("shop-item-" + i)}>
                  <b>{ article.type === "relic" ? <img src={"/images/relic.png"}/> : "" }{ article.value.name }</b>
                  { article.type === "card" ? "" : <span><br/>{article.value.effect}</span> }
                  { this.tooltipTouch && <div className="tooltip-button" onClick={() => this.run.room.shop.buy(i)}>Buy</div> }
                  </Tooltip>
                {
                    article.type === "card" ?
                    <div className="shop-article-info" onClick={() => this.setState({focus: article.value})}>
                      <Img src="/images/info.png"/>
                    </div> : ""
                  }
                </div>
              )
            }
            </div>
          </div>
          <div className="game-merchant">
            <Entity src={ this.state.model.room.shop.merchant } onSelect={() => {}}/>
          </div>
          <div className="shop-sign">
            <div className="shop-name">{ this.state.model.room.shop.model.name }</div>
            <div className="shop-description">{ this.state.model.room.shop.model.description }</div>
          </div>
          <div className="fight-end-turn"><SceneButton onClick={() => this.run.room.cleanup()}>Move on</SceneButton></div>
        </div> : ""
      }
      { this.state.model.room?.fight && this.state.model.room.fight.rewarding ? <Reward src={this.state.model.room.fight} collect={i => this.run.room.fight.collect(i)} collectCard={i => this.run.room.fight.collectCard(i)} cleanup={() => this.run.room.cleanup()}/> : "" }
      {
        this.state.model.room?.fight ?
        <div className={"fight-scene " + (this.controller.name)}>
          <Champion src={this.state.model.room.fight.player} onSelect={() => {}}/>
          <Enemies
            hoverTarget={enemy => {
              if (this.state.enemy !== enemy)
                this.setState({hoverTarget: enemy});
            }}
            targeting={this.targeting ? this.controller.selected : undefined}
            src={this.state.model.room.fight.enemies}
            onSelect={this.onSelectEntity.bind(this)}/>
          <div id="mana" onPointerDown={e => this.toggleTooltip("mana", e.pointerType === "touch")} className="mana-count-wrapper">
            <Img src="/images/mana.png"/>
            <div className="mana-count">{this.state.model.room.fight.mana}</div>
          </div>
          <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "mana"} target="mana" toggle={() => this.toggleTooltip("mana")}><b>Mana</b><br/>Helps you pay for your cards.</Tooltip>
          <Hand targeting={this.targeting} target={this.state.hoverTarget} src={this.state.model.room.fight.hand} onFocus={model => this.setState({tooltip: null, focus: model})} onSelect={this.onSelectCard.bind(this)} getHalo={card => this.controller?.cardHalo?.(card)}/>
          <div className="locations-info">
            <div id="deck-info" className="location-info deck-info" onClick={() => this.setState({deckviewer: this.state.model.room.fight.deck.cards.map(c => c.model)})}>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <Img className="location-icon" src="/images/deck.png"/><div className="location-count deck-count">{this.state.model.room.fight.deck.count}</div>
            </div>
            <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "deck-info"} target="deck-info" toggle={() => this.toggleTooltip("deck-info")}><b>Cards in deck</b><br/>Draw your cards from this pile.</Tooltip>
            <div id="discard-info" className="location-info discard-info" onClick={() => this.setState({deckviewer: this.state.model.room.fight.discard.cards.map(c => c.model)})}>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <div className="card-shuffle-deck"><img src="/images/nothing.png"/></div>
              <Img className="location-icon" src="/images/hand.png"/>
            <div className="location-count discard-count">{this.state.model.room.fight.discard.count}</div></div>
            <Tooltip className="tooltip" placement="top" isOpen={this.state.tooltip === "discard-info"} target="discard-info" toggle={() => this.toggleTooltip("discard-info")}><b>Cards in discard</b><br/>Shuffled back into your deck at end of turn.</Tooltip>
          </div>
          <div className="fight-end-turn"><SceneButton onClick={() => this.endTurn()}>End turn</SceneButton></div>
        </div> : ""
      }
      {
        this.state.model.droppingItem ?
        <div className="inventory-drop-text">
          Use or drop an item.
        </div> : ""
      }
      {
        this.state.model.droppingRelic ?
        <div className="inventory-drop-text">
          Drop an equipment.
        </div> : ""
      }
      {
        this.state.model.campfireChoices ?
        <Campfire src={this.state.model} choices={this.state.model.campfireChoices} onSelect={choice => this.run.chooseCampfire(choice)}/> : ""
      }
      {
        this.state.model.pathChoices ?
        <Campfire src={this.state.model} choices={this.state.model.pathChoices} onSelect={choice => this.run.choosePath(choice)}/> : ""
      }
      { this.state.model.choosingCard && !this.state.model.levelingUp ?
        <div className="fight-loot-card lightbox-container">
          <div className="lightbox-inner">
            <CardSelector options={this.state.model.options} onFocus={model => this.setState({tooltip: null, focus: model})} onSelect={i => this.run.collectCard(i)} onSkip={this.run.deckbuilder ? undefined : () => this.run.skipChoice()}/>
          </div>
        </div> : ""
      }
      {
        this.state.model.levelingUp && !this.state.model.droppingCard ?
        <LevelUp champion={this.state.model.champion} options={this.state.model.options} collectCard={i => this.run.collectCard(i)} onSkip={() => this.run.skipChoice()}/> : ""
      }
      {
        this.state.model.droppingCard ?
        <CardDropper deck={this.state.model.deck} onFocus={model => this.setState({tooltip: null, focus: model})} dropCard={i => this.run.dropCard(i)}/> : ""
      }
      {
        this.state.deckviewer ?
        <DeckViewer deck={this.state.deckviewer} onClose={() => this.setState({deckviewer: false})}/> : ""
      }
      {
        this.state.focus ? <CardBox src={this.state.focus} open={true} onClose={() => this.setState({focus:null})}/> : ""
      }
      {
        !this.state.unlocks && (this.state.model.over || this.state.model.win) ?
        <div className={"game-over" + (this.state.model.win ? " game-over-win" : "")}>
          <h2>{ this.state.model.win ? "Victory" : "Game Over"}</h2>
          <div className="game-over-quote">
          {
            this.state.model.win ?
            (
              this.run.depth < (premium ? 3 : 3) && !this.run.storyEvent && this.run.quest && Library.getQuest(this.run.quest).type === "floor" ?
              <div>
                <span>The maze has grown... and so can you.</span>
                <span>Growth level {this.run.depth+1} unlocked on future runs.</span>
                <span>Floor {this.run.depth+1} unlocked on future runs.</span>
                { this.run.depth >= 2 ? <span>You can start from floor {this.run.depth} on future runs.</span> : "" }
              </div> :
              <div>
              {
                premium && progress.level <= 3 ?
                <div>
                  <span>The maze calls for more.</span>
                  <span>Complete floor {progress.level} for more rewards.</span>
                </div> :
                <div>
                  <span>The maze values your ambition.</span>
                  <span>{ quest ? "Accomplish your destiny." : "More content in a future update."}</span>
                </div>
              }
              </div>

            ) :
            <div>
              <span>Maybe next time...</span>
            </div>
          }
          </div>
          <div className="game-over-recap">
            <div className="game-over-champion">{ this.state.model.champion.model.name }</div>
            <div className="game-over-stats">
              <div className="game-over-stat">
                <div className="game-over-stat-name">Level</div><div className="game-over-stat-value">{ this.run.stats.level }</div>
              </div>
              <div className="game-over-stat">
                <div className="game-over-stat-name">Rooms cleared</div><div className="game-over-stat-value">{ this.run.stats.roomsCleared }</div>
              </div>
              <div className="game-over-stat">
                <div className="game-over-stat-name">Bosses slain</div><div className="game-over-stat-value">{ this.run.stats.bossesSlain }</div>
              </div>
            </div>
          </div>
          {
            quest && quest.active && this.state.model.win ? "" :
            <div className="game-over-runes">
              {"+" + (this.run.runes || 0)}<img className="game-over-runes-icon" src="/images/rune.png"/>
            </div>
          }
          {
            quest && quest.active && this.state.model.win ?
            <MainButton onClick={quest.callback ?? (() => {})}>{quest.text}</MainButton> :
            <div>{ this.run.zen && this.run.over && <MainButton onClick={() => this.run.continue()}>Continue</MainButton> }<MainButton to="/play">Main Menu</MainButton></div>
          }
        </div> : ""
      }
      {
        this.state.unlocks &&
      <div className="scene-unlock">
        <div className="lightbox-container" onClick={() => {
            let unlocks = this.state.unlocks.slice();
            if (unlocks.length <= 1)
              this.setState({unlocks: undefined});
            else {
              unlocks.shift();
              this.setState({unlocks: unlocks});
            }
          }}>
          <div className="lightbox-inner">
            <div className={"scene-unlock-inner unlock-" + this.state.unlocks[0].type}>
              <div className="scene-unlock-text">{this.state.unlocks[0].text}</div>
              <div className="scene-unlock-img">
                <Img key={"unlock-" + this.state.unlocks[0].img} src={this.state.unlocks[0].img}/>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
      <div className="lightbox-container time-down">
        <div className="lightbox-inner">
          <Img className="time-down-img" src="/images/time.png"/>
        </div>
      </div>
      {
        this.state.cutscene ?
        <Cutscene back={() => this.cutsceneManager.back()} skip={() => this.cutsceneManager.skip()} continue={() => this.cutsceneManager.continue()} src={this.state.cutscene}/> : ""
      }
      </div>
		);
	}
}