import Card from './Card';
import Champion from './Champion';
import Room from './Room';
import Library from '../utility/Library';
import User from '../../User';

export default class Run {

	init (broadcaster, champion, difficulty, startingFloor=1) {

		this.difficultySetting = difficulty;
		this.champion = new Champion(this, champion, startingFloor);
		this.startingFloor = startingFloor;
		this.deck = this.buildStartingDeck(this.champion);
		this.floors = [];
		this.broadcaster = broadcaster;
		this.cardChance = 0.5;
		this.eventChance = 0;
		this.maxFloor = Math.min(User.premium ? 3 : 3, User.progress[this.difficultySetting][this.champion.model.key].level);
		this.roomsCleared = 0;
		this.bossesSlain = 0;
		this.runes = 0;
		this.time = 12;
		this.quest = User.progress[difficulty][champion].quest;
	}

	start () {

		this.notify('gamestate', this.serialize());
		this.newFloor();
		if (this.deckbuilder) {
			this.floor.push(new Room(this, { type: "deckbuilder" }));
		}
		else {
			this.notify("startrun", this);
			this.newRoom(this.map.shift()[0]);
		}
		this.room.enter();
	}

	get deckbuilder () {

		return this.depth < this.startingFloor;
	}

	next () {

		if (this.deckbuilder) {
			while (this.depth < this.startingFloor)
				this.newFloor();
			this.setupPathChoice();
			return;
		}
		if (this.storyEvent) {
			this.victory();
			return;
		}
		if (this.map.length <= 0) {
			if (this.depth >= this.maxFloor) {
				this.victory();
				return;
			}
			this.newFloor();
		}
		/*this.newRoom(this.roomChoices()[0]);
		this.room.enter();*/
		this.campfireChoices = ["rest", "explore"];
		if (this.champion.level < this.champion.maxLevel)
			this.campfireChoices.push("worship");
		this.notify('campfire', this, this.campfireChoices);
	}

	chooseCampfire (choice) {

		switch (choice) {
		case "rest":
			this.champion.heal(this.restHealing);
			break;
		case "explore":
			this.cardChoice();
			return;
		case "worship":
			this.champion.loseCorruption(this.worshipCorruption);
			this.champion.gainExp(this.worshipExp);
			let lvup = this.champion.checkLevelUp();
			if (lvup)
				return;
			break;
		default: break;
		}

		delete this.campfireChoices;
		this.notify('choosecampfire', this);
		this.setupPathChoice();
	}

	get restHealing () { return this.depth * (this.zen ? 30 : (this.casual ? 15 : 10)); }
	get worshipExp () { return this.depth * 5 - Math.min(this.depth * 5, this.champion.corruption); }
	get worshipCorruption () { return Math.min(this.depth * 5, this.champion.corruption); }

	gameOver () {

		this.over = true;
		this.notify('gameover', this);
	}

	victory () {

		this.win = true;
		this.notify('victory', this);
	}

	get rooms () { return this.floors.flat(); }

	get room () { return this.floor ? this.floor[this.floor.length-1] : null; }

	get floor () { return this.floors ? this.floors[this.floors.length-1] : null; }

	get depth () { return this.floors.length; }

	get stats () {

		return {
			champion: this.champion.model.key,
			roomsCleared: this.roomsCleared,
			bossesSlain: this.bossesSlain,
			level: this.champion.level
		}
	}

	buildStartingDeck (champion) {

		if (this.startingFloor > 2)
			return [];
		if (this.startingFloor > 1)
			return [
				Library.getCard(champion.model.startingDeck[0]),
				Library.getCard(champion.model.startingDeck[7]),
				Library.getCard(champion.model.startingDeck[13]),
				Library.getCard(champion.model.startingDeck[14])
				]

		return champion.model.startingDeck.map(key => Library.getCard(key));
	}

	addCard (model) {

		this.deck.push(model);
		this.notify('addcard', this, model);
		if (this.deckbuilder && this.room.type !== "shop") {
			if (this.deck.length >= 15) {
				this.newRoom({ type: "shop", merchant: "Mezuri" });
				this.room.enter();
			} else {
				if (this.deck.length === 14) {
					let options = this.cardChoiceLevel(this.champion.level);
					this.notify('newcardchoice', this, options);
				}
				else if (this.deck.length < 6 || (this.deck.length < 4 && this.startingFloor === 2))
					this.cardChoice(Math.max(1, this.champion.level-2));
				else
					this.cardChoice(this.champion.level-1);
			}
		} else
			this.dropChoice();
	}

	removeCard (n) {

		this.deck.splice(n, 1);
		this.notify('removecard', this, n);
	}

	setupMap () {

		this.map = Array.from({ length: 13 }, () => []);
		this.map[0].push({ type: "fight", difficulty: "easy" });
		this.map[1].push({ type: "fight", difficulty: "easy" });
		this.map[2].push({ type: "fight", difficulty: "easy" });
		this.map[12].push({ type: "fight", difficulty: "boss" });
		let elite1 = Math.floor(Math.random() * 5) + 7;
	    let elite2;
	    do {
	        elite2 = Math.floor(Math.random() * 5) + 7;
	    } while (Math.abs(elite1 - elite2) < 2);
	    for (let i = 1; i < 12; i++) {
	    	let always = i <= 2 || i === elite1 || i === elite2 ? false : true;
	    	if (always || Math.random() < 0.6)
	    		this.map[i].push({ type: "fight", difficulty: "medium" });
	    	if (i === elite1 || i === elite2)
	    		this.map[i].push({ type: "fight", difficulty: "elite" });
	    }
	    const isStartingFloor = this.startingFloor === this.depth;
	    let itemlist = Object.values(Library.items).filter(i => i.floors && i.floors.includes(this.depth+1) && !i.champion || i.champion === this.champion.model.key || Array.isArray(i.champion) && i.champion.includes(this.champion.model.key));
	    this.map.forEach(f => f.forEach(params => {
	    	let points = { easy: 6, medium: 12, elite: 18, boss: 30 }[params.difficulty] * (this.depth+1) * (0.8 + Math.random() * 0.4);
	    	let expratio = 0.4 + Math.random() * 0.2;
	    	let exp = Math.round(points * expratio * 1.2);
	    	points -= points * expratio;
	    	const difficultyToItemChance = { easy: 0, medium: 0.2, elite: 0.8, boss: 1 }
	    	let possibleItems = itemlist.filter(i => i.points < 0.6 * points);
	    	let item = undefined;
	    	if (possibleItems.length > 0 && Math.random() < difficultyToItemChance[params.difficulty]) {
	    		item = possibleItems[Math.floor(Math.random() * possibleItems.length)];
	    		points -= item.points;
	    	}
	    	let gold = Math.round(points * 3);
	    	params.reward = { exp, gold };
			if (item)
			    params.reward.item = item;
	    }))
		let shop1;
	    do {
	        shop1 = isStartingFloor ? Math.floor(Math.random() * 11) + 1 : Math.floor(Math.random() * 5) + 7;
	    } while (this.map[shop1].length >= 2);
		this.map[shop1].push({ type: "shop" });
	    if (Math.random() < isStartingFloor ? 0.25 : 0.5) {
	    	let shop2;
		    do {
		        shop2 = isStartingFloor ? Math.floor(Math.random() * 11) + 1 : Math.floor(Math.random() * 5) + 7;
		    } while (Math.abs(shop1 - shop2) < 2 || this.map[shop2].length >= 2);
			this.map[shop2].push({ type: "shop" });
		}
	}

	newFloor () {

		this.setupMap();
		this.champion.heal(this.zen ? this.champion.maxhp : (this.casual ? 100 : 50) * this.depth);
		this.floors.push([]);
		this.time = 12;
		this.notify('newfloor', this, this.map.slice());
	}

	get difficulty () {

		return this.difficultySetting;
	}

	get zen () {

		return this.difficultySetting === "zen";
	}

	get casual () {

		return this.difficultySetting === "casual";
	}

	get challenge () {

		return this.difficultySetting === "challenge";
	}

	get hardcore () {

		return this.difficultySetting === "hardcore";
	}

	setupPathChoice () {

		/*let choices = [];
		if (this.time >= 10) {
			choices.push({ type: "fight", difficulty: "easy" });
		} else if (this.time >= 0) {
			choices.push({ type: "fight", difficulty: "medium" });
		} else if (this.time === 0) {
			choices.push({ type: "fight", difficulty: "boss" });
		}

		this.pathChoices = choices;*/
		this.pathChoices = this.map.shift();
		this.notify("pathchoices", this, this.pathChoices);
	}

	choosePath (choice) {

		delete this.pathChoices;
		this.notify("choosepath", this, choice);
		this.newRoom(choice);
		this.room.enter()
	}

	pickRandomRoom(rooms) {
	    // Calculate the total sum of frequencies
	    const totalFrequency = rooms.reduce((sum, room) => sum + (room.frequency || 1), 0);
	    
	    // Generate a random number between 0 and the totalFrequency
	    let random = Math.random() * totalFrequency;
	    
	    // Loop through the rooms and subtract their frequencies from the random number
	    for (let i = 0; i < rooms.length; i++) {
	        random -= rooms[i].frequency || 1;
	        if (random < 0) {
	            return rooms[i];
	        }
	    }
	}

	newRoom (params) {

		let rooms = Object.values(Library.rooms);
		rooms = rooms.filter(r => r.type === params.type && r.floor === this.depth);	
		rooms = rooms.filter(r => r.difficulty === params.difficulty);
		if (params.type === "fight")
			rooms = rooms.filter(r => this.rooms.filter(a => a.model.key === r.key).length === 0);
		if (params.merchant)
			rooms = rooms.filter(r => r.merchant === params.merchant);
		if (!this.challenge)
			rooms = rooms.filter(r => !r.challenge);
		let room = this.pickRandomRoom(rooms);

		this.floor.push(new Room(this, room, params.reward));
	}

	cardChoice (level) {

		if (!level)
			level = this.champion.level;

		this.choosingCard = true;

		let cards = Object.values(Library.cards);
		cards = cards.filter(c => c.champion === this.champion.model.key && c.level > 0 && c.level > level - 3 && c.level <= level);
		let cardlevels = Array.from({ length: level }, (_, i) => cards.filter(c => c.level === i + 1));
		let lv1 = cardlevels.length > 3 ? cardlevels[cardlevels.length-3] : cardlevels[0];
		let lv2 = cardlevels.length > 2 ? cardlevels[cardlevels.length-2] : cardlevels[0];
		let lv3 = cardlevels[cardlevels.length-1];
		let options = [];
		for (let i = 0; i < 3; i++) {
			let option = null;
			let r = Math.random();
			let lv = r < 0.5 ? lv3 : lv2;
			while (option === null || options.includes(option))
				option = lv[Math.floor(Math.random() * lv.length)];
			options.push(option);
		}

		this.options = options;
		this.notify('newcardchoice', this, options);
	}

	cardChoiceLevel (level) {

		this.choosingCard = true;

		let cards = Object.values(Library.cards);
		cards = cards.filter(c => c.champion === this.champion.model.key && c.level > 0 && c.level == level);
		let options = [];
		for (let i = 0; i < 3; i++) {
			let option = null;
			while (option === null || options.includes(option))
				option = cards[Math.floor(Math.random() * cards.length)]
			options.push(option)
		}

		this.options = options;
		return options;
	}

	levelUp () {

		this.levelingUp = true;
		return this.cardChoiceLevel(this.champion.level);
	}

	collectCard (n) {

		if (!this.options || !this.choosingCard)
			return;

		let choice = this.options[n];

		delete this.options;
		delete this.choosingCard;
		this.notify('collectcard', this, n);

		this.addCard(choice);
	}

	dropChoice () {

		this.droppingCard = true;

		this.notify('dropcardchoice', this);
	}

	skipChoice () {

		delete this.options;
		delete this.choosingCard;
		this.notify('skipcardchoice', this);

		if (this.levelingUp) {

			delete this.room.choices;
			delete this.levelingUp;
			this.room.cleanup();
		} else if (this.room?.choices && this.room?.type === "fight") {

			delete this.room.choices;
			this.room.startFight();
		}

		if (this.campfireChoices) {

			delete this.campfireChoices;
			if (this.room)
				delete this.room.choices;
			this.notify('choosecampfire', this);
			this.setupPathChoice();
		}
	}

	dropCard (n) {

		if (!this.droppingCard)
			return;

		this.removeCard(n);

		delete this.droppingCard;
		this.notify('dropcard', this, n);

		if (this.levelingUp) {

			delete this.levelingUp;
		}
		if (this.campfireChoices) {

			delete this.campfireChoices;
			this.notify('choosecampfire', this);
			this.setupPathChoice();
		}
	}

	startStoryEvent (enemies, background, audio) {

		delete this.win;
		if (this.room && this.room.fight)
			this.room.fight.cleanup();
		this.storyEvent = true;
		this.background = background;
		this.audio = audio;
		this.notify("storyevent", this, background, audio);
		//this.champion.heal(this.champion.maxhp);
		this.floors.push([]);
		this.notify('newfloor', this);
		this.floor.push(new Room(this, {
			"key": -1,
			"type": "story",
			"enemies": enemies
		}));
		this.room.enter();
	}

	continue () {

		if (!this.over)
			return;
		delete this.over;
		this.notify('continue', this);
		this.champion.heal(this.champion.maxhp);
		if (this.room && this.room.fight)
			this.room.fight.start(this.room.model.enemies);
	}

	notify (type, ...data) {

		if ((this.over && type !== "gameover") || (this.win && type !== "victory"))
			return;
		if (this.broadcaster)
			this.broadcaster.notify(type, data);
	}

	subscribe (type, notify) {

		if (this.broadcaster)
			return this.broadcaster.subscribe(type, notify);
		return () => {};
	}

	serialize () {

		return {
			champion: this.champion.serialize(),
			deck: this.deck.map(card => Object.assign({}, card)),
			floors: this.floors.map(floor => floor.map(room => room.serialize())),
			levelingUp: this.levelingUp,
			choosingCard: this.choosingCard,
			droppingCard: this.droppingCard,
			options: this.options ? this.options.map(option => option.key) : null,
			cardChance: this.cardChance,
			eventChance: this.eventChance,
			maxFloor: this.maxFloor,
			storyEvent: this.storyEvent,
			background: this.background,
			audio: this.audio,
			roomsCleared: this.roomsCleared,
			bossesSlain: this.bossesSlain,
			difficultySetting: this.difficultySetting,
			runes: this.runes,
			time: this.time,
			startingFloor: this.startingFloor,
			campfireChoices: this.campfireChoices,
			pathChoices: this.pathChoices,
			map: this.map ? this.map.slice() : undefined,
			droppingItem: this.droppingItem,
			droppingRelic: this.droppingRelic,
			quest: this.quest
		}
	}

	static build (src, broadcaster) {

		let run = new Run();
		run.broadcaster = broadcaster;
		run.difficultySetting = src.difficultySetting || "challenge";
		run.champion = Champion.build(run, src.champion);
		run.deck = src.deck.map(card => Object.assign({}, card));
		run.floors = src.floors.map(floor => floor.map(room => Room.build(run, room)));
		run.levelingUp = src.levelingUp;
		run.choosingCard = src.choosingCard;
		run.droppingCard = src.droppingCard;
		run.cardChance = src.cardChance;
		run.eventChance = src.eventChance;
		run.maxFloor = src.maxFloor;
		run.storyEvent = src.storyEvent;
		run.background = src.background;
		run.audio = src.audio;
		run.roomsCleared = src.roomsCleared;
		run.bossesSlain = src.bossesSlain;
		run.runes = src.runes;
		run.time = src.time;
		run.startingFloor = src.startingFloor;
		run.campfireChoices = src.campfireChoices;
		run.pathChoices = src.pathChoices;
		run.map = src.map;
		run.droppingItem = src.droppingItem;
		run.droppingRelic = src.droppingRelic;
		run.quest = src.quest;
		if (src.options)
			run.options = src.options.map(option => Library.getCard(option));
		return run;
	}
}
